<template>
  <div class="row justify-content-center">
    <div class="col-5 col-sm-4 col-md-3 text-center my-3">
      <StripeLogo />
    </div>
    <StripePaymentText />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
export default ({
  components: {
    StripeLogo: defineAsyncComponent(() => import('../components/StripeLogo.vue')),
    StripePaymentText: defineAsyncComponent(() => import('../components/StripePaymentText.vue'))
  },
  name: 'StripeInfo'
})
</script>
